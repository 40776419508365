import { useAuth0 } from "@auth0/auth0-react";
import { REFERRAL_QUERY_PARAMETER_KEY, TRY_FOR_FREE_REFERRAL_VALUE } from "@constants/constants";
import React from "react";

import { Button, Paragraph, Small } from "@fronterahealth/frontera-ui-components";
import logo from "@fronterahealth/frontera-ui-components/assets/logo.png";

import useMediaQuery from "@hooks/useMediaQuery";

interface LinkItemProps {
  href: string;
  label: string;
  closeSidebar?: () => void;
}
const LinkItem: React.FC<LinkItemProps> = ({ label, href, closeSidebar }) => {
  return (
    <Paragraph
      onClick={closeSidebar ? () => closeSidebar() : undefined}
      colorType="primary"
      displayType="loud"
      className="hover:text-text-secondary transition-all"
    >
      <a href={href}>{label}</a>
    </Paragraph>
  );
};

export const SiteHeader: React.FC = () => {
  const { user, isAuthenticated } = useAuth0();
  const isDesktop = useMediaQuery("(min-width: 960px)");
  const { loginWithRedirect, logout } = useAuth0();

  if (!isDesktop) {
    return (
      <div className="flex items-center w-full sticky justify-between px-8 top-0 bg-limestone-50 z-20 pb-4">
        <div className="my-4 flex max-w-[192px] h-[40px] max-h-[40px]  items-center">
          <img className="max-h-[32px]" src={logo} alt="" />
        </div>
        <Button
          text={"Try for Free"}
          appearance={"primary"}
          onClick={() => {
            loginWithRedirect({
              authorizationParams: {
                audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                redirect_uri: window.location.href,
                screen_hint: "signup",
              },
              appState: {
                returnTo: `${import.meta.env.VITE_REDIRECT_URL}?${REFERRAL_QUERY_PARAMETER_KEY}=${TRY_FOR_FREE_REFERRAL_VALUE}`,
              },
            });
          }}
        />
      </div>
    );
  }

  if (isDesktop) {
    return (
      <div className="flex items-center w-full sticky justify-between px-8 top-0 bg-limestone-50 z-20 ">
        <div className="my-4 flex max-w-[192px] h-[40px] max-h-[40px]  items-center">
          <img className="max-h-[40px]" src={logo} alt="" />
        </div>
        <div className="flex items-center gap-x-4">
          <LinkItem href="#demo" label="Demo" />
          <LinkItem href="#features" label="Features" />
          <LinkItem href="#pricing" label="Pricing" />
          <LinkItem href="#faq" label="FAQ" />
        </div>
        {user && isAuthenticated ? (
          <div className="flex items-center gap-x-4">
            <Small>Welcome, {user.name}</Small>
            <a href={import.meta.env.VITE_PORTAL_URL} target="_blank">
              <Button text={"Go To Assessment Builder"} appearance={"primary"} className="bg-hatch-700" />
            </a>
            <Button
              onClick={() =>
                logout({
                  logoutParams: {
                    returnTo: window.location.href,
                  },
                })
              }
              text={"Logout"}
              appearance={"link"}
              buttonAction="destructive"
            />
          </div>
        ) : (
          <div className="flex items-center gap-x-4">
            <Button
              text={"Login"}
              appearance={"link"}
              onClick={() => {
                const returnTo = `${import.meta.env.VITE_REDIRECT_URL}`;
                console.debug("<> Redirecting, here is the returnTo ", { returnTo });
                loginWithRedirect({
                  authorizationParams: {
                    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                    redirect_uri: window.location.href,
                    screen_hint: "login",
                  },
                  appState: {
                    returnTo,
                  },
                });
              }}
            />
            <Button
              onClick={() => {
                const returnTo = `${import.meta.env.VITE_REDIRECT_URL}?${REFERRAL_QUERY_PARAMETER_KEY}=${TRY_FOR_FREE_REFERRAL_VALUE}`;
                console.debug("<> Redirecting, here is the returnTo ", { returnTo });
                loginWithRedirect({
                  authorizationParams: {
                    audience: import.meta.env.VITE_AUTH0_AUDIENCE,
                    redirect_uri: window.location.href,
                    screen_hint: "signup",
                  },
                  appState: {
                    returnTo,
                  },
                });
              }}
              text={"Try for Free"}
              appearance={"primary"}
              className="bg-hatch-700"
            />
          </div>
        )}
      </div>
    );
  }
};

export const SideNavContent: React.FC<{ setSidebarOpen: (bool: boolean) => void }> = ({ setSidebarOpen }) => {
  return (
    <ul>
      <div className="flex flex-col items-start gap-y-4">
        <LinkItem closeSidebar={() => setSidebarOpen(false)} href="#features" label="Features" />
        <LinkItem closeSidebar={() => setSidebarOpen(false)} href="#pricing" label="Pricing" />
        <LinkItem closeSidebar={() => setSidebarOpen(false)} href="#faq" label="FAQ" />
      </div>
    </ul>
  );
};
