export const copy = {
  splash: {
    subheading:
      "The Assessment Builder by Frontera Health empowers BCBAs to create personalized, comprehensive Initial Assessments in significantly less time, without compromising on quality.",
    waitlistButton: "Join Waitlist",
    explorePricingButton: "Explore Pricing",
  },
  features: {
    title: "Designed with your productivity in mind",
    firstFeature: {
      title: "AI-Powered Co-Pilot",
      subtitle: "We utilize state-of-the-art AI models to aid clinicians, not replace them.",
    },
    secondFeature: {
      title: "Goal and Target Suggestions",
      subtitle: "Leveraging your clinical documentation we suggest personalized treatment plans",
    },
    thirdFeature: {
      title: "User-Friendly Interface",
      subtitle: "Built & tested with industry leaders to meet the needs of all clinicians.",
    },
  },
  howItWorks: {
    title: "How It Works",
    subtitle:
      "Our innovative tool is designed to enhance the efficiency and effectiveness of your assessments, providing a seamless user experience.",
    steps: {
      one: {
        title: "Data Collection",
        subtitle: "Upload intake call recordings, assessment scores, diagnosis reports, and clinical notes",
      },
      two: {
        title: "AI Analysis",
        subtitle: "Our advanced AI processes the data to generate comprehensive assessment reports.",
      },
      three: {
        title: "Treatment Plan Creation",
        subtitle: "Receive AI-suggested goals and targets tailored to each client's needs.",
      },
      four: {
        title: "Review and Edit",
        subtitle: "Easily review, edit, and finalize the assessment report with our intuitive interface.",
      },
      five: {
        title: "Export and Share",
        subtitle: "Export the completed assessment report for further editing or send it directly to payors",
      },
    },
  },
  pricing: {
    title: "Our Pricing",
    subtitle:
      "We offer a straightforward pricing plan for individual clinicians and a customizable group plan for clinics.",
    individual: {
      title: "Individual",
      subtitle: "Everything you need to get started as an individual provider. Includes one free assessment.",
      costPerAssessment: 50,
      costLabel: "per assessment",
    },
    group: {
      title: "Group",
      subtitle: "For clinics with 10 or more providers.",
      ctaButton: "Contact for Price",
    },
  },
  faq: {
    title: "Frequently Asked Questions",
    subtitleCopy: "Can't find the answer here?",
    subtitleCta: "Contact our support team",
    subtitleCtaHref:
      "mailto:support@fronterahealth.com?subject=Assessment Builder Support: Additional Assistance Needed",
    questions: [
      {
        question: "Which Assessments do you support?",
        answer:
          "We support a number of assessments including ABLLS-R, AFLS, APSI, CFQL, ESDM, PCA PEAK, SRS2, VB-MAPP, Vineland and plan to support ABAS, EFL, PDDBI, PSI, Socially Savvy and Brief soon.",
      },
      {
        question: "How does the AI suggest goals and targets?",
        answer:
          "Our AI analyzes the integrated data to identify patterns and suggest personalized goals and targets based on best practices.",
      },
      {
        question: "What format will my completed Assessment Reports be in?",
        answer: "We output your completed Assessment Reports as a DOCX, allowing you to make any edits you need after.",
      },
    ],
  },
};
